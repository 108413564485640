import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../styles/NavigationBar.css';
import { jwtDecode } from 'jwt-decode';
import Fingerprint2 from 'fingerprintjs2';
import styles from '../styles/FullScreen.module.css';
import chatPic from '../chatpic.webp'; // Update the path according to your file structure
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link } from 'react-router-dom';
import checkUploadStatus from './CheckUploadStatus';
import { api } from '../api'; // Assuming this is your Axios instance
import CryptoJS from 'crypto-js';


function ReserveRoomContainer() {
    const [dayOfWeek, setDayOfWeek] = useState('');

    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showGuestAlert, setShowGuestAlert] = useState(false); // State to show/hide guest alert
    const [hours, setHours] = useState(1); // Default to 1 hour
    const [numweeks, setNumweeks] = useState(''); // Step 1: Declare numweeks state
    const [isSubmitting, setIsSubmitting] = useState(false); // State to manage button disabled state

    const navigate = useNavigate();
  
    const convertDayOfWeekToDate = (dayOfWeek, reservationTime) => {
      const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const desiredDayIndex = days.indexOf(dayOfWeek);
      const today = new Date();
      const todayDayIndex = today.getDay();
      
      let addDays = desiredDayIndex - todayDayIndex;
      // If desired day is today, check if reservation time has passed
      if (addDays === 0) {
        const currentTime = today.getHours() + (today.getMinutes() / 60);
        const [reservationHour, reservationMinute] = reservationTime.split(':').map(Number);
        const reservationTimeInHours = reservationHour + (reservationMinute / 60);
    
        // If reservation time has passed, push reservation to next week
        if (currentTime-2 >= reservationTimeInHours) {
          addDays += 7;
        }
      } else if (addDays < 0) {
        // If desired day has already passed this week, schedule for next week
        addDays += 7;
      }
    
      
      today.setDate(today.getDate() + addDays);
      return today.toISOString().split('T')[0]; // Format as "YYYY-MM-DD"
    };
    
    // Encrypt function using AES from CryptoJS
    const encryptWithAES = (text) => {
      const passphrase = 'your-default-passphrase'; // Use a secure passphrase
      return CryptoJS.AES.encrypt(text, passphrase).toString();
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
  
      if (isSubmitting) {
        return; // Prevent form from being submitted again if already submitting
      }

      setIsSubmitting(true); // Disable the submit button

      // Encrypt password before sending
      const encryptedPassword = encryptWithAES(password);

      {/*
      // Show guest alert if user is not logged in
      if (!localStorage.getItem('token')) {
        setShowGuestAlert(true);
        return;
      }
    */}

    // Convert day of the week to the nearest date
    const date = convertDayOfWeekToDate(dayOfWeek, time);

      try {
        const response = await api.reserveRoom({ date, time, username, password: encryptedPassword, hours });

  
        console.log('Reservation successful:', response.data);
        navigate('/support'); // Redirect to a success page
      } catch (err) {
        setError('Failed to reserve room. Please try again.');
        console.error('Reservation error:', err.message);
        setIsSubmitting(false); // Re-enable the submit button if there is an error

    }
    };

    // Function to clear placeholder text on focus
    const handleFocus = (e, type) => {
      if (type === 'date' && e.target.value === 'YYYY-MM-DD') setDate('');
      if (type === 'time' && e.target.value === 'HH:MM') setTime('');
    };

    // Function to restore placeholder text on blur if the input is empty
    const handleBlur = (e, type) => {
      if (type === 'date' && e.target.value === '') setDate('YYYY-MM-DD');
      if (type === 'time' && e.target.value === '') setTime('HH:MM');
    };

  
    return (
      <div className={styles['reserve-room-container']}>
        <h2></h2>
        {error && <div className="alert alert-danger">{error}</div>}
        <form onSubmit={handleSubmit} className={styles['reserve-room-form']}>
          <select
            value={dayOfWeek}
            onChange={(e) => setDayOfWeek(e.target.value)}
            required
            className={styles['form-input']}
          >
            <option value="">Select Day</option>
            <option value="Sunday">Sunday</option>
            <option value="Monday">Monday</option>
            <option value="Tuesday">Tuesday</option>
            <option value="Wednesday">Wednesday</option>
            <option value="Thursday">Thursday</option>
            <option value="Friday">Friday</option>
            <option value="Saturday">Saturday</option>
          </select>

          <input
            type="time"
            placeholder="Time"
            value={time}
            onChange={(e) => setTime(e.target.value)}
            required
            className={styles['form-input']} // Use the form-input class

          />
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            className={styles['form-input']} // Use the form-input class

          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className={styles['form-input']} // Use the form-input class

          />
          <input
            type="text"
            placeholder="Number of weeks"
            value={numweeks}
            onChange={(e) => setNumweeks(e.target.value)} // Step 3: Update numweeks on change
            required
            className={styles['form-input']} // Use the form-input class

          />
          <select
            value={hours}
            onChange={(e) => setHours(e.target.value)}
            required
            className={styles['form-input']}
          >
            <option value="1">1 Hour</option>
            <option value="2">2 Hours</option>
            <option value="3">3 Hours</option>
          </select>
          <button type="submit" className={styles['submit-button']} disabled={isSubmitting}>
            {isSubmitting ? 'Processing...' : 'Reserve'}
          </button>
        </form>
  
        {showGuestAlert && (
          <div className={styles['guest-alert-overlay']}>
            <div className={styles['guest-alert']}>
              <p>Hey there! Noticed you're not signed in yet. Please <Link to="/login">log in</Link> or <Link to="/signup">sign up</Link> to reserve a room.</p>
            </div>
          </div>
        )}
      </div>
    );
  }
  
  export default ReserveRoomContainer;
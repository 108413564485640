import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import styles from '../styles/FullScreen.module.css';
import chatPic from '../chatpic.webp'; // Ensure the path matches your file structure
import { Helmet } from 'react-helmet';

function ReserveRoomStepOne() {
  const [dayOfWeek, setDayOfWeek] = useState('');
  const [time, setTime] = useState('');
  const [hours, setHours] = useState(1);
  const [numweeks, setNumweeks] = useState(''); // Step 1: Declare numweeks state

  const navigate = useNavigate();

    // Generate time options from 08:00 to 21:00
    const timeOptions = Array.from({ length: 14 }, (_, i) => {
      const hour = 8 + i; // Start at 08:00
      return `${hour < 10 ? '0' + hour : hour}:00`; // Format as HH:00
    });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('ReserveRoomStepOne - handleSubmit:', { dayOfWeek, time, hours });
    navigate('/securepage', { state: { dayOfWeek, time, hours } });
  };

  return (
    <div className={styles['full-screen-container']}>
      <Helmet>
        <title>Library Addict - Your Personal Library Room Reservation Assistant</title> 
        <link rel="canonical" href="https://www.libraryaddict.org/home" /> 
      </Helmet>
      <LazyLoadImage
        alt="Background"
        effect="blur"
        src={chatPic} // Use the imported image path
        wrapperClassName={styles['background-image']}
      />
      <div className={styles['foreground-content']}>
        <form onSubmit={handleSubmit} className={styles['reserve-room-form']}>
          <h2 className={`${styles['marquee']}`}>
            <span className={styles['marquee-content']}></span>
          </h2>

          <h1>TAU Automated Library Room Reserver </h1> {/* Here's the h1 header */}

          <select
            value={dayOfWeek}
            onChange={(e) => setDayOfWeek(e.target.value)}
            required
            className={styles['form-input']}
          >
            <option value="">Select Day</option>
            <option value="Sunday">Sunday</option>
            <option value="Monday">Monday</option>
            <option value="Tuesday">Tuesday</option>
            <option value="Wednesday">Wednesday</option>
            <option value="Thursday">Thursday</option>
            <option value="Friday">Friday</option>
            <option value="Saturday">Saturday</option>
          </select>
          {/* Time Selection */}
          <select
            value={time}
            onChange={(e) => setTime(e.target.value)}
            required
            className={styles['form-input']}
          >
            <option value="">Select Time</option>
            {timeOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <select
            value={hours}
            onChange={(e) => setHours(e.target.value)}
            required
            className={styles['form-input']}
          >
            <option value="1">1 Hour</option>
            <option value="2">2 Hours</option>
            <option value="3">3 Hours</option>
          </select>
          <input
            type="text"
            placeholder="Number of weeks"
            value={numweeks}
            onChange={(e) => setNumweeks(e.target.value)}
            required
            className={styles['form-input']}
          />
          <button type="submit" className={styles['submit-button']}>Next</button>
        </form>
      </div>
    </div>
  );
}

export default ReserveRoomStepOne;

import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import '../styles/ErrorPage.css';


const ErrorPage = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/home');
  };

  return (
    <div className="error-page-container">
      <div className="error-container">
        <Helmet>
          <title>request sent - Library Addict</title>
          <meta name="description" content="support request sent." />
          <link rel="canonical" href="https://www.libraryaddict.org/support" /> 
          <meta name="robots" content="noindex, follow" /> 
        </Helmet>
        
        <h1>your request has been sent</h1>
        <p>Go and have fun studing, we will deal with the trouble for you.</p>
        <button onClick={handleGoBack} className="go-back-btn">
          Go Back to Home
        </button>

      </div>
    </div>
  );
}

export default ErrorPage;

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import styles from '../styles/FullScreen1.module.css';
import chatPic from '../chatpic.webp';
import { api } from '../api';
import CryptoJS from 'crypto-js';

function ReserveRoomStepTwo() {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [dayOfWeek, setDayOfWeek] = useState('');
  const [time, setTime] = useState('');
  const [hours, setHours] = useState(1);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [numweeks, setNumweeks] = useState(''); // Step 1: Declare numweeks state

  useEffect(() => {
    console.log('ReserveRoomStepTwo - useEffect state:', state);
    if (state) {
      const { dayOfWeek, time, hours } = state;
      setDayOfWeek(dayOfWeek);
      setTime(time);
      setHours(hours);
    } else {
      navigate('/reserve-room/step-one');
    }
  }, [state, navigate]);

  const encryptWithAES = (text) => {
    const passphrase = 'your-secret-passphrase';
    return CryptoJS.AES.encrypt(text, passphrase).toString();
  };

  const convertDayOfWeekToDate = (dayOfWeek, reservationTime) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const desiredDayIndex = days.indexOf(dayOfWeek);
    const today = new Date();
    const todayDayIndex = today.getDay();

    let addDays = desiredDayIndex - todayDayIndex;
    if (addDays === 0) {
      const currentTime = today.getHours() + (today.getMinutes() / 60);
      const [reservationHour, reservationMinute] = reservationTime.split(':').map(Number);
      const reservationTimeInHours = reservationHour + (reservationMinute / 60);

      if (currentTime >= reservationTimeInHours) {
        addDays += 7;
      }
    } else if (addDays < 0) {
      addDays += 7;
    }

    today.setDate(today.getDate() + addDays);
    return today.toISOString().split('T')[0];
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const date = convertDayOfWeekToDate(dayOfWeek, time);
    const encryptedPassword = encryptWithAES(password);

    console.log('ReserveRoomStepTwo - handleSubmit:', { date, time, hours, username, encryptedPassword });

    try {
      const response = await api.reserveRoom({
        date,
        time,
        hours,
        username,
        password: encryptedPassword,
      });
      console.log('ReserveRoomStepTwo - reservation successful:', response);
      navigate('/support');
    } catch (err) {
      console.error('ReserveRoomStepTwo - reservation error:', err);
      setError('Failed to reserve room. Please try again.');
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles['full-screen-container1']}>
      <LazyLoadImage
        alt="Chat background"
        effect="blur"
        //src={chatPic} // Use the imported image path
        wrapperClassName={styles['background-image']}
      />
      <div className={styles['foreground-content1']}>
        {error && <div className="alert alert-danger">{error}</div>}
        <form onSubmit={handleSubmit} className={styles['reserve-room-form']}>
          <h1>Securly Enter Your TAU Login Details</h1> {/* Here's the h1 header */}

          <div className={styles['input-container']}>
            <img src="/person.webp" alt="Logo 1" className={styles['input-logo']} />
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              className={styles['form-input']}
            />
          </div>

          <div className={styles['input-container']}>
            <img src="/lock.webp" alt="Logo 2" className={styles['input-logo']} />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className={styles['form-input']}
            />
          </div>

          <button type="submit" disabled={isSubmitting} className={styles['submit-button']}>
            {isSubmitting ? 'Processing...' : 'Reserve'}
          </button>
        </form>
      </div>
      <div className={styles['security-assurances']}>
        <div className={styles['security-text']}>
          <p>Your security is our priority. We follow industry best practices to protect your information.</p>
        </div>
        <div className={styles['logo-container']}>
          <div className={styles['trust-logo']}><img src="/norton-small.png" alt="Trust Seal 1" /></div>
          <div className={styles['trust-logo']}><img src="/comodo-small.png" alt="Trust Seal 2" /></div>
          <div className={styles['trust-logo']}><img src="/mcafee-small.png" alt="Trust Seal 3" /></div>
        </div>
      </div>
    </div>
  );
}

export default ReserveRoomStepTwo;


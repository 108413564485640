import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../styles/NavigationBar.css';
import { jwtDecode } from 'jwt-decode';
import Fingerprint2 from 'fingerprintjs2';
import styles from '../styles/FullScreen.module.css';
import chatPic from '../chatpic.webp'; // Update the path according to your file structure
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link } from 'react-router-dom';
import checkUploadStatus from './CheckUploadStatus';
import { api } from '../api'; // Assuming this is your Axios instance


function DeleteReservation() {
    const [dayOfWeek, setDayOfWeek] = useState('');

    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showGuestAlert, setShowGuestAlert] = useState(false); // State to show/hide guest alert
    const [hours, setHours] = useState(1); // Default to 1 hour

    const navigate = useNavigate();
  

    const handleSubmit = async (e) => {
      e.preventDefault();
    
      if (!username) {
        setError('Username is required');
        return;
      }
    
      try {
        const response = await api.deleteUserData(username);
        console.log('Data deletion successful:', response.data);
        navigate('/support'); // Redirect to a success or confirmation page
      } catch (err) {
        setError('Failed to delete user data. Please try again.');
        console.error('Deletion error:', err.message);
      }
    };
    

  
    return (
      <div className={styles['reserve-room-container']}>
        <h2></h2>
        {error && <div className="alert alert-danger">{error}</div>}
        <form onSubmit={handleSubmit} className={styles['reserve-room-form']}>
          <h1>Enter TAU Username To delete all previous requests</h1>

          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            className={styles['form-input']} // Use the form-input class

          />
          <button type="submit" className={styles['submit-button']}>Delete</button> {/* Use submit-button class */}
        </form>
  
        {showGuestAlert && (
          <div className={styles['guest-alert-overlay']}>
            <div className={styles['guest-alert']}>
              <p>Hey there! Noticed you're not signed in yet. Please <Link to="/login">log in</Link> or <Link to="/signup">sign up</Link> to reserve a room.</p>
            </div>
          </div>
        )}
      </div>
    );
  }
  
  export default DeleteReservation;
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import '../styles/NavigationBar.css';
import styles from '../styles/FullScreen.module.css';
import chatPic from '../chatpic.webp'; // Update the path according to your file structure
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import DeleteReservation from './DeleteReservation'; // Adjust path as needed
import { api } from '../api'; // Assuming this is your Axios instance

function ComplaintsPage({ backgroundImageLoaded, enableScrollHandling = true }) {
  const navigate = useNavigate();
  const touchStartRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);



  return (
    <div className={styles['full-screen-container']}>
      <Helmet>
        <title>Library Addict - Your Personal Library Room Reservation Assistant</title> 
        <link rel="canonical" href="https://www.libraryaddict.org/complaints" /> 
      </Helmet>
      {backgroundImageLoaded && (
        <LazyLoadImage
          alt="An intuitive online reservation platform for booking study rooms and reading spaces in libraries."
          effect="blur"
          src={chatPic}
          wrapperClassName={styles['background-image']}
        />
      )}

        <div className={styles['foreground-content']}>
          <DeleteReservation 
          isMobile={isMobile} 
          />
        </div>
      <div className={styles['security-assurances']}>
        <div className={styles['security-text1']}>
          <p>for any questions contact libraryaddicthelp@gmail.com.</p>
        </div>
        <div className={styles['logo-container']}>
        </div>
      </div>
    </div>

  );
}

export default ComplaintsPage;

import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import '../styles/ErrorPage.css';

const ErrorPage = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/home');
  };

  return (
    <div className="error-page-container">
      <div className="error-container">
        <Helmet>
          <title>404 Not Found - Library Addict</title>
          <meta name="description" content="Encountered a 404 error at Library Addict? We're here to simplify your library room reservation experience." />
          <link rel="canonical" href="https://www.libraryaddict.org/404" /> 
          <meta name="robots" content="noindex, follow" /> 
        </Helmet>
        
        <h1>404 - Page Not Found</h1>
        <p>Oops! The page you are looking for does not exist. It might have been moved or deleted. But don't worry, we can help you find your way back.</p>
        <button onClick={handleGoBack} className="go-back-btn">
          Go Back to Home
        </button>

        <div className="blog-section1">
          <p>While you're here, why not explore our platform a bit more? Whether you need a quiet study room or a space for your group project, Library Addict makes it easy to find and reserve the perfect spot. Let's get you back to planning your next library visit.</p>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;
